<template>
  <div class="app-spinner-wrapper">
    <b-spinner class="app-spinner" type="grow" label="Spinning"></b-spinner>
  </div>
</template>

<script>
export default {
  name: "Spinner",
};
</script>

<style scoped>
.app-spinner-wrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.app-spinner {
  background: #ff762b;
  width: 50px;
  height: 50px;
}
</style>
