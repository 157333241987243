import ApiService from "@/services";

const state = {
  propertyData: null,
  properties: [],
  fetching: false,
};

const getters = {};

const mutations = {
  setProperties(state, data) {
    state.properties = data;
    state.fetching = false;
  },
  setProperty(state, data) {
    state.propertyData = data;
    state.fetching = false;
  },
  setFetching(state, data) {
    state.fetching = data;
  }
};

const actions = {
  async searchProperties({ commit }, params) {
    commit("setFetching", true);
    const response = await ApiService.get(`properties/search`, { params });
    commit("setProperties", response.data.data);
  },
  async getPropertyById({ commit }, id) {
    commit("setFetching", true);
    const response = await ApiService.get(`properties/${id}`);
    commit("setProperty", response.data.data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
